.session-page {
  background: #f3f5f7;

  .header {
    text-align: center;
    padding-top: 50px;
    
    .logo {
      color: #222;
      font-size: 34px;
      text-decoration: none;
    }

    h4 {
      color: #222;
      font-size: 25px;
      font-weight: 400;
      margin-top: 50px;
    }

    p {
      color: #444;
      margin-top: 10px;
    }
  }
  
  .wrapper {
    margin: 0 auto;
    width: 51%;
    margin-top: 45px;
    background: #fff;
    padding: 15px 50px 40px 50px;
    border-radius: 4px;
    border-top: 2px solid #42b1e2;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);

    @media(max-width: 991px) {
      width: 67%;
    }

    @media(max-width: 767px) {
      width: 100%;
    }

    .formy {
      margin-top: 22px;

      .form-group {
        label {
          color: #46515F;
        }
      }

      .checkbox {
        label {
          color: #666;
          font-size: 13px;
        }

        input {
          position: relative;
          top: -1px;
        }
      }
    }
  }

  .already-account {
    text-align: center;
    color: #444;
    margin-top: 25px;
    margin-bottom: 70px;
    font-size: 13px;

    a {
      text-decoration: none;
    }
  }
}
