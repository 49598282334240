.slider-section {
  margin-top: 90px;

  @media (max-width: 991px) {
    margin-top: 60px;
  }

  .header {
    text-align: center;
    font-size: 25px;
    color: #555;
    line-height: 25px;
    font-weight: 400;
    margin-bottom: 50px;

    @media(max-width: 767px) {
      font-size: 20px;
      margin-bottom: 0;
    }
  }

  .slide-wrapper {
    border-bottom: 1px solid #E4E4E4;

    .slideshow {
      background: url("../images/slider-bg.png") 0 0 no-repeat;
      margin: 0 auto;
      margin-top: 35px;
      width: 844px;
      height: 418px;
      position: relative;
      overflow: hidden;

      @media(max-width: 991px) {
        background: none;
        width: inherit;
        height: 340px;
      }

      @media(max-width: 767px) {
        height: 290px;
      }

      @media(max-width: 530px) {
        height: 240px;
      }

      @media(max-width: 450px) {
        height: 205px;
      }

      .btn-nav {
        width: 48px;
        height: 51px;
        position: absolute;
        top: 55%;
        z-index: 999;
        cursor: pointer;
        opacity: 0;

        @include transition(all .3s ease);

        &.active {
          opacity: 1;

          @include transform(translateX(0px) !important);
        }

        &.prev {
          background: url("../images/slider/slide-left.png")  0 0 no-repeat;
          left: 91px;

          @include transform(translateX(-48px));

          @media(max-width: 991px) {
            left: 30px;
          }
        }
        
        &.next {
          background: url("../images/slider/slide-right.png")  0 0 no-repeat;
          right: 91px;

          @include transform(translateX(48px));

          @media(max-width: 991px) {
            right: 29px;
          }
        }
      }     

      .slide {
        width: 100%;
        height: 100%;
        position: absolute;       

        &.active {
          img {
            opacity: 1;

            @include transform(translateY(0px));
          }
        }

        img {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          opacity: 0;

          @include transform(translateY(300px));
          @include transition(all .6s ease);

          @media(max-width: 767px) {
            width: 100%;
          }
        }
      }
    }   
  }
}