.timeline-bg {
  background: #f7f8fa;
}

.timeline-wrapper {
  margin-top: 80px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 5px;
    height: 100%;
    left: 0;
    background: #e7e8ec;
    right: 0;
    margin: 0 auto;
    z-index: -1;
  }

  .year {
    text-align: center;
    margin-bottom: 50px;
    font-weight: 600;
    margin-top: 50px;
    font-size: 16px;

    &:nth-child(1) {
      margin-top: 0px;
      margin-bottom: 60px;
    }

    &.last {
      margin-bottom: 0;
      font-size: 21px;
    }

    span {
      display: inline-block;
      background: #E7E8EC;
      padding: 6px 20px;
      color: #5A5A5A;
      border-radius: 3px;
    }
  }

  /* the first node that comes right next to a year */
  .year + .node {
    margin-top: -20px;
  }

  .node {
    position: relative;
    float: right;
    clear: right;
    width: 44%;
    margin: 35px 0;
    border-radius: 5px;

    @media(max-width: 767px) {
      width: 100%;
    }

    &.left {
      float: left;
      clear: left;

      &:before {
        right: -51px;
        left: inherit;

        @media(max-width: 991px) {
          right: -42px;
          width: 43px;
        }
      }

      .marker {
        left: inherit;
        right: -71px;

        @media(max-width: 1199px) {
          right: -64px;
        }

        @media(max-width: 991px) {
          right: -50px;
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      width: 50px;
      height: 4px;
      background: #E7E8EC;
      left: -51px;
      top: 62px;

      @media(max-width: 991px) {
        left: -42px;
        width: 43px;
      }

      @media(max-width: 767px) {
        display: none;
      }
    }

    .marker {
      position: absolute;
      width: 17px;
      height: 17px;
      border-radius: 25px;
      background: #e7e8ec;
      left: -70px;
      top: 55px;
      border: 3px solid #F7F8FA;

      @media(max-width: 1199px) {
        left: -64px;
      }

      @media(max-width: 991px) {
        left: -50px;
      }

      @media(max-width: 767px) {
        display: none;
      }
    }

    .entry {
      position: relative;
      padding: 20px;
      height: 400px;
      background-size: cover;
      background-position: center center;
      border-radius: 5px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.26);

      &:before {
        position: absolute;
        top: 50%;
        bottom: 0;
        left: 0;
        right: 0;
        content: '';
        border-radius: 0 0 5px 5px;
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(15, 24, 49, 0.89) 100%);
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(15, 24, 49, 0.89) 100%);
          background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(15, 24, 49, 0.89) 100%);
          background: linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(15, 24, 49, 0.89) 100%);
      }

      &.smallish {
        height: 320px;

        .intro {
          top: 68%;
        }
      }

      &.short-entry {
        background: #E7E8EC;
        color: #5c6269;
        height: inherit;
        padding: 13px;
        box-shadow: none;
        position: relative;
        top: 41px;
        left: -1px;
        margin-bottom: 20px;
        text-shadow: 1px 1px rgba(255, 255, 255, 0.4);

        &:before {
          background: none;
        }
      }

      .intro {
        position: relative;
        top: 75%;
        color: #FFF;
        z-index: 9;
        letter-spacing: .3px;

        h4 {
          font-size: 20px;
          text-shadow: 1px 1px rgba(0, 0, 0, 0.69);
          letter-spacing: .5px;

          @media(max-width: 767px) {
            font-size: 17px;
          }
        }

        p {
          font-weight: 300;

          @media(max-width: 767px) {
            font-size: 13px;
          }
        }
      }
    }

    .news {
      position: relative;
      background-size: cover;
      background-position: center center;
      border-radius: 5px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.26);
      background: #fff;

      section {
        padding: 30px 20px 60px 20px;

        h3 {
          margin-top: 0;
          font-size: 17px;
          line-height: 22px;
        }

        p {
          margin-top: 21px;
          line-height: 22px;
          font-size: 13px;
          color: #7A7979;
        }
      }

      footer {
        position: absolute;
        bottom: 0;
        padding: 12px 20px;
        width: 100%;

        font-size: 12px;
        font-weight: 500;
        color: #9EAEBE;

        .tag {
          position: relative;
          padding-left: 27px;
          text-transform: uppercase;

          span {
            font-size: 21px;
            position: absolute;
            top: -5px;
            left: 0;
          }
        }
      }
    }
  }
}
