.about-us-slider {
  margin-top: 80px;

  .header {
    text-align: left;

    h3 {
      font-size: 26px;
      color: #444;
      line-height: 25px;
      font-weight: 500;
    }

    p {
      font-size: 15px;
      font-weight: 400;
      color: #666;
      margin-top: 20px;
      line-height: 24px;

      @media(min-width: 992px) {
        width: 48%;
      }
    }
  }

  .flexslider {
    margin-top: 40px;
    box-shadow: none;
    border-radius: 0px;

    .slides {       
      img {
        max-height: 400px;
      }
    }

    .flex-control-paging {
      bottom: -35px;

      li {
        margin: 0 7px;

        a {
          background: #e6e6e6;
          box-shadow: inset 0 0 2px rgba(0,0,0,0.3);

          @include transition(all 0.2s linear);

          &.flex-active {
            background: #3785b9;
          }
        }
      }
    }
  }
}

.about-us-team {
  margin-top: 100px;

  .header {
    font-size: 24px;
    color: #444;
    line-height: 25px;
    font-weight: 500;
    margin-bottom: 25px;
  }

  p {
    color: #666;
    line-height: 24px;
    margin-bottom: 15px;
    font-size: 15px;

    @media(min-width: 992px) {
      width: 88%;
    }
  }

  .stats {
    margin-top: 60px;
    font-size: 16px;
    color: #757575;

    strong {
      font-size: 33px;
      color: #656565;
      font-weight: 500;
      position: relative;
      top: 1px;
      margin-right: 5px;
    }

    .col-sm-3 {
      border-bottom: 1px solid #efefef;
      padding-bottom: 25px;

      @media(max-width: 767px) {
        padding-bottom: 10px;
        margin-bottom: 20px;
      }
    }
  }

  .team {
    margin-top: 90px;

    .team-row {
      text-align: center;
      margin-bottom: 65px;

      @media(max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    img {
      margin: 0px 20px;
      max-width: 110px;
      max-height: 110px;
      border-radius: 50%;
      position: relative;
      top: 0px;
      box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.4);
      
      @include transition(all 0.3s);
      
      @media(max-width: 991px) {
        margin: 0px 10px 20px 0;
      }

      &:hover {
        top: -5px;
        box-shadow: 0 10px 18px 0px rgba(0,0,0,.3);
      }
    }

    .tooltip-inner {
      padding: 12px 18px;
      font-size: 13px;
      background: rgba(0, 0, 0, .8);
      border: 1px solid #000;
      border-bottom: 0px;
    }

    .tooltip-arrow {
      border-top-color: rgba(0, 0, 0, .8);
    }
  }
}
