html.portfolio-page {
  background: #F5F9FF;
  background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0,rgba(255,255,255,0)),color-stop(1,rgba(255,255,255,0.5)));
  background-image: -webkit-linear-gradient(top,rgba(255,255,255,0) 0,rgba(255,255,255,0.5) 100%);
  background-image: -moz-linear-gradient(top,rgba(255,255,255,0) 0,rgba(255,255,255,0.5) 100%);
  background-image: -o-linear-gradient(top,rgba(255,255,255,0) 0,rgba(255,255,255,0.5) 100%);
  background-image: -ms-linear-gradient(top,rgba(255,255,255,0) 0,rgba(255,255,255,0.5) 100%);
  background-image: linear-gradient(top,rgba(255,255,255,0) 0,rgba(255,255,255,0.5) 100%);
  -webkit-background-size: 100% 30%;
  -moz-background-size: 100% 30%;
  background-size: 100% 30%;
  background-repeat: no-repeat;
  background-position: 0 100%;
}

.portfolio-body {
  background: none;
  background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0,#fff),color-stop(25%,rgba(255,255,255,0)));
  background-image: -webkit-linear-gradient(top,#fff 0,rgba(255,255,255,0) 25%);
  background-image: -moz-linear-gradient(top,#fff 0,rgba(255,255,255,0) 25%);
  background-image: -o-linear-gradient(top,#fff 0,rgba(255,255,255,0) 25%);
  background-image: -ms-linear-gradient(top,#fff 0,rgba(255,255,255,0) 25%);
  background-image: linear-gradient(top,#fff 0,rgba(255,255,255,0) 25%);
}

.portfolio-showcase {
  margin-top: 70px;
  
  .header {
    text-align: left;
    margin-bottom: 50px;

    h3 {
      font-size: 28px;
      color: #596166;
      line-height: 25px;
      font-weight: 500;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      color: #8D959B;
      line-height: 24px;

      @media(min-width: 992px) {
        width: 48%;
      }
    }
  }
  
  .project {
    background: #fff;
    padding: 40px 60px;
    border-radius: 5px;
    margin-bottom: 35px;
    box-shadow: 0 -1px 0 rgba(0,0,0,0.05), 0 1px 0 rgba(0,0,0,0.05), -1px 0 0 rgba(0,0,0,0.04), 1px 0 0 rgba(0,0,0,0.04), 0 1px 3px rgba(0,0,0,0.12);

    @media(max-width: 767px) {
      padding: 40px 35px 35px 35px;;
    }

    h3 {
      color: #585F64;
      font-size: 22px;
      margin-top: 0px;
      text-align: center;
    }

    .screen {
      margin-top: 45px;
      text-align: center;

      img {
        margin: 0 auto;
      }
    }

    .description {
      margin-top: 40px;
      color: #7D8186;
      line-height: 21px;
    }

    .divider {
      height: 1px;
      width: 100%;
      background-color: #ddd;
      position: relative;
      margin-top: 45px;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, 0.75)), color-stop(0.5, rgba(250,250,250,0)), color-stop(1, rgba(255, 255, 255, 0.75)));
        background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.75) 0%, rgba(250,250,250,0) 50%, rgba(255, 255, 255, 0.75) 100%);
        background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.75) 0%, rgba(250,250,250,0) 50%, rgba(255, 255, 255, 0.75) 100%);
        background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.75) 0%, rgba(250,250,250,0) 50%, rgba(255, 255, 255, 0.75) 100%);
        background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.75) 0%, rgba(250,250,250,0) 50%, rgba(255, 255, 255, 0.75) 100%);
        background-image: linear-gradient(left, rgba(255, 255, 255, 0.75) 0%, rgba(250,250,250,0) 50%, rgba(255, 255, 255, 0.75) 100%);
      }
    }

    .visit {
      margin-top: 30px;
      text-align: center;
    }
  }
}
