@font-face {
	font-family: 'brankic';
	src:url("#{sfontsPath}/brankic.eot");
	src:url("#{$fontsPath}/brankic.eot?#iefix") format("embedded-opentype"),
		url("#{$fontsPath}/brankic.ttf") format("truetype"),
		url("#{$fontsPath}/brankic.woff") format("woff"),
		url("#{$fontsPath}/brankic.svg#brankic") format("svg");
	font-weight: normal;
	font-style: normal;
}

[class^="brankic-"], [class*=" brankic-"] {
	font-family: 'brankic';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.brankic-number:before {
	content: "\e600";
}
.brankic-number2:before {
	content: "\e601";
}
.brankic-number3:before {
	content: "\e602";
}
.brankic-number4:before {
	content: "\e603";
}
.brankic-number5:before {
	content: "\e604";
}
.brankic-number6:before {
	content: "\e605";
}
.brankic-number7:before {
	content: "\e606";
}
.brankic-number8:before {
	content: "\e607";
}
.brankic-number9:before {
	content: "\e608";
}
.brankic-number10:before {
	content: "\e609";
}
.brankic-number11:before {
	content: "\e60a";
}
.brankic-number12:before {
	content: "\e60b";
}
.brankic-number13:before {
	content: "\e60c";
}
.brankic-number14:before {
	content: "\e60d";
}
.brankic-number15:before {
	content: "\e60e";
}
.brankic-number16:before {
	content: "\e60f";
}
.brankic-number17:before {
	content: "\e610";
}
.brankic-number18:before {
	content: "\e611";
}
.brankic-number19:before {
	content: "\e612";
}
.brankic-number20:before {
	content: "\e613";
}
.brankic-quote:before {
	content: "\e614";
}
.brankic-quote2:before {
	content: "\e615";
}
.brankic-tag:before {
	content: "\e616";
}
.brankic-tag2:before {
	content: "\e617";
}
.brankic-link:before {
	content: "\e618";
}
.brankic-link2:before {
	content: "\e619";
}
.brankic-cabinet:before {
	content: "\e61a";
}
.brankic-cabinet2:before {
	content: "\e61b";
}
.brankic-calendar:before {
	content: "\e61c";
}
.brankic-calendar2:before {
	content: "\e61d";
}
.brankic-calendar3:before {
	content: "\e61e";
}
.brankic-file:before {
	content: "\e61f";
}
.brankic-file2:before {
	content: "\e620";
}
.brankic-file3:before {
	content: "\e621";
}
.brankic-files:before {
	content: "\e622";
}
.brankic-phone:before {
	content: "\e623";
}
.brankic-tablet:before {
	content: "\e624";
}
.brankic-window:before {
	content: "\e625";
}
.brankic-monitor:before {
	content: "\e626";
}
.brankic-ipod:before {
	content: "\e627";
}
.brankic-tv:before {
	content: "\e628";
}
.brankic-camera:before {
	content: "\e629";
}
.brankic-camera2:before {
	content: "\e62a";
}
.brankic-camera3:before {
	content: "\e62b";
}
.brankic-film:before {
	content: "\e62c";
}
.brankic-film2:before {
	content: "\e62d";
}
.brankic-film3:before {
	content: "\e62e";
}
.brankic-microphone:before {
	content: "\e62f";
}
.brankic-microphone2:before {
	content: "\e630";
}
.brankic-microphone3:before {
	content: "\e631";
}
.brankic-drink:before {
	content: "\e632";
}
.brankic-drink2:before {
	content: "\e633";
}
.brankic-drink3:before {
	content: "\e634";
}
.brankic-drink4:before {
	content: "\e635";
}
.brankic-coffee:before {
	content: "\e636";
}
.brankic-mug:before {
	content: "\e637";
}
.brankic-ice-cream:before {
	content: "\e638";
}
.brankic-cake:before {
	content: "\e639";
}
.brankic-inbox:before {
	content: "\e63a";
}
.brankic-download:before {
	content: "\e63b";
}
.brankic-upload:before {
	content: "\e63c";
}
.brankic-inbox2:before {
	content: "\e63d";
}
.brankic-checkmark:before {
	content: "\e63e";
}
.brankic-checkmark2:before {
	content: "\e63f";
}
.brankic-cancel:before {
	content: "\e640";
}
.brankic-cancel2:before {
	content: "\e641";
}
.brankic-plus:before {
	content: "\e642";
}
.brankic-plus2:before {
	content: "\e643";
}
.brankic-minus:before {
	content: "\e644";
}
.brankic-minus2:before {
	content: "\e645";
}
.brankic-notice:before {
	content: "\e646";
}
.brankic-notice2:before {
	content: "\e647";
}
.brankic-cog:before {
	content: "\e648";
}
.brankic-cogs:before {
	content: "\e649";
}
.brankic-cog2:before {
	content: "\e64a";
}
.brankic-warning:before {
	content: "\e64b";
}
.brankic-health:before {
	content: "\e64c";
}
.brankic-suitcase:before {
	content: "\e64d";
}
.brankic-suitcase2:before {
	content: "\e64e";
}
.brankic-suitcase3:before {
	content: "\e64f";
}
.brankic-picture:before {
	content: "\e650";
}
.brankic-pictures:before {
	content: "\e651";
}
.brankic-pictures2:before {
	content: "\e652";
}
.brankic-android:before {
	content: "\e653";
}
.brankic-marvin:before {
	content: "\e654";
}
.brankic-pacman:before {
	content: "\e655";
}
.brankic-cassette:before {
	content: "\e656";
}
.brankic-watch:before {
	content: "\e657";
}
.brankic-chronometer:before {
	content: "\e658";
}
.brankic-watch2:before {
	content: "\e659";
}
.brankic-alarm-clock:before {
	content: "\e65a";
}
.brankic-time:before {
	content: "\e65b";
}
.brankic-time2:before {
	content: "\e65c";
}
.brankic-headphones:before {
	content: "\e65d";
}
.brankic-wallet:before {
	content: "\e65e";
}
.brankic-checkmark3:before {
	content: "\e65f";
}
.brankic-cancel3:before {
	content: "\e660";
}
.brankic-eye:before {
	content: "\e661";
}
.brankic-position:before {
	content: "\e662";
}
.brankic-site-map:before {
	content: "\e663";
}
.brankic-site-map2:before {
	content: "\e664";
}
.brankic-cloud:before {
	content: "\e665";
}
.brankic-upload2:before {
	content: "\e666";
}
.brankic-chart:before {
	content: "\e667";
}
.brankic-chart2:before {
	content: "\e668";
}
.brankic-chart3:before {
	content: "\e669";
}
.brankic-chart4:before {
	content: "\e66a";
}
.brankic-chart5:before {
	content: "\e66b";
}
.brankic-chart6:before {
	content: "\e66c";
}
.brankic-location:before {
	content: "\e66d";
}
.brankic-download2:before {
	content: "\e66e";
}
.brankic-basket:before {
	content: "\e66f";
}
.brankic-folder:before {
	content: "\e670";
}
.brankic-gamepad:before {
	content: "\e671";
}
.brankic-alarm:before {
	content: "\e672";
}
.brankic-alarm-cancel:before {
	content: "\e673";
}
.brankic-phone2:before {
	content: "\e674";
}
.brankic-phone3:before {
	content: "\e675";
}
.brankic-image:before {
	content: "\e676";
}
.brankic-open:before {
	content: "\e677";
}
.brankic-sale:before {
	content: "\e678";
}
.brankic-direction:before {
	content: "\e679";
}
.brankic-map:before {
	content: "\e67a";
}
.brankic-trashcan:before {
	content: "\e67b";
}
.brankic-vote:before {
	content: "\e67c";
}
.brankic-graduate:before {
	content: "\e67d";
}
.brankic-lab:before {
	content: "\e67e";
}
.brankic-tie:before {
	content: "\e67f";
}
.brankic-football:before {
	content: "\e680";
}
.brankic-eight-ball:before {
	content: "\e681";
}
.brankic-bowling:before {
	content: "\e682";
}
.brankic-bowling-pin:before {
	content: "\e683";
}
.brankic-baseball:before {
	content: "\e684";
}
.brankic-soccer:before {
	content: "\e685";
}
.brankic-3d-glasses:before {
	content: "\e686";
}
.brankic-microwave:before {
	content: "\e687";
}
.brankic-refrigerator:before {
	content: "\e688";
}
.brankic-oven:before {
	content: "\e689";
}
.brankic-washing-machine:before {
	content: "\e68a";
}
.brankic-mouse:before {
	content: "\e68b";
}
.brankic-smiley:before {
	content: "\e68c";
}
.brankic-sad:before {
	content: "\e68d";
}
.brankic-mute:before {
	content: "\e68e";
}
.brankic-hand:before {
	content: "\e68f";
}
.brankic-radio:before {
	content: "\e690";
}
.brankic-satellite:before {
	content: "\e691";
}
.brankic-medal:before {
	content: "\e692";
}
.brankic-medal2:before {
	content: "\e693";
}
.brankic-switch:before {
	content: "\e694";
}
.brankic-key:before {
	content: "\e695";
}
.brankic-cord:before {
	content: "\e696";
}
.brankic-locked:before {
	content: "\e697";
}
.brankic-unlocked:before {
	content: "\e698";
}
.brankic-locked2:before {
	content: "\e699";
}
.brankic-unlocked2:before {
	content: "\e69a";
}
.brankic-magnifier:before {
	content: "\e69b";
}
.brankic-zoom-in:before {
	content: "\e69c";
}
.brankic-zoom-out:before {
	content: "\e69d";
}
.brankic-stack:before {
	content: "\e69e";
}
.brankic-stack2:before {
	content: "\e69f";
}
.brankic-stack3:before {
	content: "\e6a0";
}
.brankic-david-star:before {
	content: "\e6a1";
}
.brankic-cross:before {
	content: "\e6a2";
}
.brankic-moon-andstar:before {
	content: "\e6a3";
}
.brankic-transformers:before {
	content: "\e6a4";
}
.brankic-batman:before {
	content: "\e6a5";
}
.brankic-space-invaders:before {
	content: "\e6a6";
}
.brankic-skeletor:before {
	content: "\e6a7";
}
.brankic-lamp:before {
	content: "\e6a8";
}
.brankic-lamp2:before {
	content: "\e6a9";
}
.brankic-umbrella:before {
	content: "\e6aa";
}
.brankic-street-light:before {
	content: "\e6ab";
}
.brankic-bomb:before {
	content: "\e6ac";
}
.brankic-archive:before {
	content: "\e6ad";
}
.brankic-battery:before {
	content: "\e6ae";
}
.brankic-battery2:before {
	content: "\e6af";
}
.brankic-battery3:before {
	content: "\e6b0";
}
.brankic-battery4:before {
	content: "\e6b1";
}
.brankic-battery5:before {
	content: "\e6b2";
}
.brankic-megaphone:before {
	content: "\e6b3";
}
.brankic-megaphone2:before {
	content: "\e6b4";
}
.brankic-patch:before {
	content: "\e6b5";
}
.brankic-pil:before {
	content: "\e6b6";
}
.brankic-injection:before {
	content: "\e6b7";
}
.brankic-thermometer:before {
	content: "\e6b8";
}
.brankic-lamp3:before {
	content: "\e6b9";
}
.brankic-lamp4:before {
	content: "\e6ba";
}
.brankic-lamp5:before {
	content: "\e6bb";
}
.brankic-cube:before {
	content: "\e6bc";
}
.brankic-box:before {
	content: "\e6bd";
}
.brankic-box2:before {
	content: "\e6be";
}
.brankic-diamond:before {
	content: "\e6bf";
}
.brankic-bag:before {
	content: "\e6c0";
}
.brankic-money-bag:before {
	content: "\e6c1";
}
.brankic-grid:before {
	content: "\e6c2";
}
.brankic-grid2:before {
	content: "\e6c3";
}
.brankic-list:before {
	content: "\e6c4";
}
.brankic-list2:before {
	content: "\e6c5";
}
.brankic-ruler:before {
	content: "\e6c6";
}
.brankic-ruler2:before {
	content: "\e6c7";
}
.brankic-layout:before {
	content: "\e6c8";
}
.brankic-layout2:before {
	content: "\e6c9";
}
.brankic-layout3:before {
	content: "\e6ca";
}
.brankic-layout4:before {
	content: "\e6cb";
}
.brankic-layout5:before {
	content: "\e6cc";
}
.brankic-layout6:before {
	content: "\e6cd";
}
.brankic-layout7:before {
	content: "\e6ce";
}
.brankic-layout8:before {
	content: "\e6cf";
}
.brankic-layout9:before {
	content: "\e6d0";
}
.brankic-layout10:before {
	content: "\e6d1";
}
.brankic-layout11:before {
	content: "\e6d2";
}
.brankic-layout12:before {
	content: "\e6d3";
}
.brankic-layout13:before {
	content: "\e6d4";
}
.brankic-layout14:before {
	content: "\e6d5";
}
.brankic-tools:before {
	content: "\e6d6";
}
.brankic-screwdriver:before {
	content: "\e6d7";
}
.brankic-paint:before {
	content: "\e6d8";
}
.brankic-hammer:before {
	content: "\e6d9";
}
.brankic-brush:before {
	content: "\e6da";
}
.brankic-pen:before {
	content: "\e6db";
}
.brankic-chat:before {
	content: "\e6dc";
}
.brankic-comments:before {
	content: "\e6dd";
}
.brankic-chat2:before {
	content: "\e6de";
}
.brankic-chat3:before {
	content: "\e6df";
}
.brankic-volume:before {
	content: "\e6e0";
}
.brankic-volume2:before {
	content: "\e6e1";
}
.brankic-volume3:before {
	content: "\e6e2";
}
.brankic-equalizer:before {
	content: "\e6e3";
}
.brankic-resize:before {
	content: "\e6e4";
}
.brankic-resize2:before {
	content: "\e6e5";
}
.brankic-stretch:before {
	content: "\e6e6";
}
.brankic-narrow:before {
	content: "\e6e7";
}
.brankic-resize3:before {
	content: "\e6e8";
}
.brankic-download3:before {
	content: "\e6e9";
}
.brankic-calculator:before {
	content: "\e6ea";
}
.brankic-library:before {
	content: "\e6eb";
}
.brankic-auction:before {
	content: "\e6ec";
}
.brankic-justice:before {
	content: "\e6ed";
}
.brankic-stats:before {
	content: "\e6ee";
}
.brankic-stats2:before {
	content: "\e6ef";
}
.brankic-attachment:before {
	content: "\e6f0";
}
.brankic-hourglass:before {
	content: "\e6f1";
}
.brankic-abacus:before {
	content: "\e6f2";
}
.brankic-pencil:before {
	content: "\e6f3";
}
.brankic-pen2:before {
	content: "\e6f4";
}
.brankic-pin:before {
	content: "\e6f5";
}
.brankic-pin2:before {
	content: "\e6f6";
}
.brankic-discout:before {
	content: "\e6f7";
}
.brankic-edit:before {
	content: "\e6f8";
}
.brankic-scissors:before {
	content: "\e6f9";
}
.brankic-profile:before {
	content: "\e6fa";
}
.brankic-profile2:before {
	content: "\e6fb";
}
.brankic-profile3:before {
	content: "\e6fc";
}
.brankic-rotate:before {
	content: "\e6fd";
}
.brankic-rotate2:before {
	content: "\e6fe";
}
.brankic-reply:before {
	content: "\e6ff";
}
.brankic-forward:before {
	content: "\e700";
}
.brankic-retweet:before {
	content: "\e701";
}
.brankic-shuffle:before {
	content: "\e702";
}
.brankic-loop:before {
	content: "\e703";
}
.brankic-crop:before {
	content: "\e704";
}
.brankic-square:before {
	content: "\e705";
}
.brankic-square2:before {
	content: "\e706";
}
.brankic-circle:before {
	content: "\e707";
}
.brankic-dollar:before {
	content: "\e708";
}
.brankic-dollar2:before {
	content: "\e709";
}
.brankic-coins:before {
	content: "\e70a";
}
.brankic-pig:before {
	content: "\e70b";
}
.brankic-bookmark:before {
	content: "\e70c";
}
.brankic-bookmark2:before {
	content: "\e70d";
}
.brankic-address-book:before {
	content: "\e70e";
}
.brankic-address-book2:before {
	content: "\e70f";
}
.brankic-safe:before {
	content: "\e710";
}
.brankic-envelope:before {
	content: "\e711";
}
.brankic-envelope2:before {
	content: "\e712";
}
.brankic-radio-active:before {
	content: "\e713";
}
.brankic-music:before {
	content: "\e714";
}
.brankic-presentation:before {
	content: "\e715";
}
.brankic-male:before {
	content: "\e716";
}
.brankic-female:before {
	content: "\e717";
}
.brankic-aids:before {
	content: "\e718";
}
.brankic-heart:before {
	content: "\e719";
}
.brankic-info:before {
	content: "\e71a";
}
.brankic-info2:before {
	content: "\e71b";
}
.brankic-piano:before {
	content: "\e71c";
}
.brankic-rain:before {
	content: "\e71d";
}
.brankic-snow:before {
	content: "\e71e";
}
.brankic-lightning:before {
	content: "\e71f";
}
.brankic-sun:before {
	content: "\e720";
}
.brankic-moon:before {
	content: "\e721";
}
.brankic-cloudy:before {
	content: "\e722";
}
.brankic-cloudy2:before {
	content: "\e723";
}
.brankic-car:before {
	content: "\e724";
}
.brankic-bike:before {
	content: "\e725";
}
.brankic-truck:before {
	content: "\e726";
}
.brankic-bus:before {
	content: "\e727";
}
.brankic-bike2:before {
	content: "\e728";
}
.brankic-plane:before {
	content: "\e729";
}
.brankic-paper-plane:before {
	content: "\e72a";
}
.brankic-rocket:before {
	content: "\e72b";
}
.brankic-book:before {
	content: "\e72c";
}
.brankic-book2:before {
	content: "\e72d";
}
.brankic-barcode:before {
	content: "\e72e";
}
.brankic-barcode2:before {
	content: "\e72f";
}
.brankic-expand:before {
	content: "\e730";
}
.brankic-collapse:before {
	content: "\e731";
}
.brankic-pop-out:before {
	content: "\e732";
}
.brankic-pop-in:before {
	content: "\e733";
}
.brankic-target:before {
	content: "\e734";
}
.brankic-badge:before {
	content: "\e735";
}
.brankic-badge2:before {
	content: "\e736";
}
.brankic-ticket:before {
	content: "\e737";
}
.brankic-ticket2:before {
	content: "\e738";
}
.brankic-ticket3:before {
	content: "\e739";
}
.brankic-microphone4:before {
	content: "\e73a";
}
.brankic-cone:before {
	content: "\e73b";
}
.brankic-blocked:before {
	content: "\e73c";
}
.brankic-stop:before {
	content: "\e73d";
}
.brankic-keyboard:before {
	content: "\e73e";
}
.brankic-keyboard2:before {
	content: "\e73f";
}
.brankic-radio2:before {
	content: "\e740";
}
.brankic-printer:before {
	content: "\e741";
}
.brankic-checked:before {
	content: "\e742";
}
.brankic-error:before {
	content: "\e743";
}
.brankic-add:before {
	content: "\e744";
}
.brankic-minus3:before {
	content: "\e745";
}
.brankic-alert:before {
	content: "\e746";
}
.brankic-pictures3:before {
	content: "\e747";
}
.brankic-atom:before {
	content: "\e748";
}
.brankic-eyedropper:before {
	content: "\e749";
}
.brankic-globe:before {
	content: "\e74a";
}
.brankic-globe2:before {
	content: "\e74b";
}
.brankic-shipping:before {
	content: "\e74c";
}
.brankic-ying-yang:before {
	content: "\e74d";
}
.brankic-compass:before {
	content: "\e74e";
}
.brankic-zip:before {
	content: "\e74f";
}
.brankic-zip2:before {
	content: "\e750";
}
.brankic-anchor:before {
	content: "\e751";
}
.brankic-locked-heart:before {
	content: "\e752";
}
.brankic-magnet:before {
	content: "\e753";
}
.brankic-navigation:before {
	content: "\e754";
}
.brankic-tags:before {
	content: "\e755";
}
.brankic-heart2:before {
	content: "\e756";
}
.brankic-heart3:before {
	content: "\e757";
}
.brankic-usb:before {
	content: "\e758";
}
.brankic-clipboard:before {
	content: "\e759";
}
.brankic-clipboard2:before {
	content: "\e75a";
}
.brankic-clipboard3:before {
	content: "\e75b";
}
.brankic-switch2:before {
	content: "\e75c";
}
.brankic-ruler3:before {
	content: "\e75d";
}
