@font-face {
	font-family: 'icomoon';
	src:url('#{$fontsPath}/icomoon.eot');
	src:url('#{$fontsPath}/icomoon.eot?#iefix') format('embedded-opentype'),
		url('#{$fontsPath}/icomoon.ttf') format('truetype'),
		url('#{$fontsPath}/icomoon.woff') format('woff'),
		url('#{$fontsPath}/icomoon.svg#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icomoon-"], [class*=" icomoon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icomoon-home:before {
	content: "\e609";
}
.icomoon-home2:before {
	content: "\e60a";
}
.icomoon-home3:before {
	content: "\e60b";
}
.icomoon-office:before {
	content: "\e60c";
}
.icomoon-newspaper:before {
	content: "\e60d";
}
.icomoon-pencil:before {
	content: "\e60e";
}
.icomoon-pencil2:before {
	content: "\e60f";
}
.icomoon-quill:before {
	content: "\e610";
}
.icomoon-pen:before {
	content: "\e611";
}
.icomoon-blog:before {
	content: "\e612";
}
.icomoon-droplet:before {
	content: "\e613";
}
.icomoon-paint-format:before {
	content: "\e614";
}
.icomoon-image:before {
	content: "\e615";
}
.icomoon-image2:before {
	content: "\e616";
}
.icomoon-images:before {
	content: "\e617";
}
.icomoon-camera:before {
	content: "\e618";
}
.icomoon-music:before {
	content: "\e619";
}
.icomoon-headphones:before {
	content: "\e61a";
}
.icomoon-play:before {
	content: "\e61b";
}
.icomoon-film:before {
	content: "\e61c";
}
.icomoon-camera2:before {
	content: "\e61d";
}
.icomoon-dice:before {
	content: "\e61e";
}
.icomoon-pacman:before {
	content: "\e61f";
}
.icomoon-spades:before {
	content: "\e620";
}
.icomoon-clubs:before {
	content: "\e621";
}
.icomoon-diamonds:before {
	content: "\e622";
}
.icomoon-pawn:before {
	content: "\e623";
}
.icomoon-bullhorn:before {
	content: "\e624";
}
.icomoon-connection:before {
	content: "\e625";
}
.icomoon-podcast:before {
	content: "\e626";
}
.icomoon-feed:before {
	content: "\e627";
}
.icomoon-book:before {
	content: "\e628";
}
.icomoon-books:before {
	content: "\e629";
}
.icomoon-library:before {
	content: "\e62a";
}
.icomoon-file:before {
	content: "\e62b";
}
.icomoon-profile:before {
	content: "\e62c";
}
.icomoon-file2:before {
	content: "\e62d";
}
.icomoon-file3:before {
	content: "\e62e";
}
.icomoon-file4:before {
	content: "\e62f";
}
.icomoon-copy:before {
	content: "\e630";
}
.icomoon-copy2:before {
	content: "\e631";
}
.icomoon-copy3:before {
	content: "\e632";
}
.icomoon-paste:before {
	content: "\e633";
}
.icomoon-paste2:before {
	content: "\e634";
}
.icomoon-paste3:before {
	content: "\e635";
}
.icomoon-stack:before {
	content: "\e636";
}
.icomoon-folder:before {
	content: "\e637";
}
.icomoon-folder-open:before {
	content: "\e638";
}
.icomoon-tag:before {
	content: "\e639";
}
.icomoon-tags:before {
	content: "\e63a";
}
.icomoon-barcode:before {
	content: "\e63b";
}
.icomoon-qrcode:before {
	content: "\e63c";
}
.icomoon-ticket:before {
	content: "\e63d";
}
.icomoon-cart:before {
	content: "\e63e";
}
.icomoon-cart2:before {
	content: "\e63f";
}
.icomoon-cart3:before {
	content: "\e640";
}
.icomoon-coin:before {
	content: "\e641";
}
.icomoon-credit:before {
	content: "\e642";
}
.icomoon-calculate:before {
	content: "\e643";
}
.icomoon-support:before {
	content: "\e644";
}
.icomoon-phone:before {
	content: "\e645";
}
.icomoon-phone-hang-up:before {
	content: "\e646";
}
.icomoon-address-book:before {
	content: "\e647";
}
.icomoon-notebook:before {
	content: "\e648";
}
.icomoon-envelop:before {
	content: "\e649";
}
.icomoon-pushpin:before {
	content: "\e64a";
}
.icomoon-location:before {
	content: "\e64b";
}
.icomoon-location2:before {
	content: "\e64c";
}
.icomoon-compass:before {
	content: "\e64d";
}
.icomoon-map:before {
	content: "\e64e";
}
.icomoon-map2:before {
	content: "\e64f";
}
.icomoon-history:before {
	content: "\e650";
}
.icomoon-clock:before {
	content: "\e651";
}
.icomoon-clock2:before {
	content: "\e652";
}
.icomoon-alarm:before {
	content: "\e653";
}
.icomoon-alarm2:before {
	content: "\e654";
}
.icomoon-bell:before {
	content: "\e655";
}
.icomoon-stopwatch:before {
	content: "\e656";
}
.icomoon-calendar:before {
	content: "\e657";
}
.icomoon-calendar2:before {
	content: "\e658";
}
.icomoon-print:before {
	content: "\e659";
}
.icomoon-keyboard:before {
	content: "\e65a";
}
.icomoon-screen2:before {
	content: "\e65b";
}
.icomoon-laptop:before {
	content: "\e65c";
}
.icomoon-mobile:before {
	content: "\e65d";
}
.icomoon-mobile2:before {
	content: "\e65e";
}
.icomoon-tablet:before {
	content: "\e65f";
}
.icomoon-tv:before {
	content: "\e660";
}
.icomoon-cabinet:before {
	content: "\e661";
}
.icomoon-drawer:before {
	content: "\e662";
}
.icomoon-drawer2:before {
	content: "\e663";
}
.icomoon-drawer3:before {
	content: "\e664";
}
.icomoon-box-add:before {
	content: "\e665";
}
.icomoon-box-remove:before {
	content: "\e666";
}
.icomoon-download:before {
	content: "\e667";
}
.icomoon-upload2:before {
	content: "\e668";
}
.icomoon-disk:before {
	content: "\e669";
}
.icomoon-storage:before {
	content: "\e66a";
}
.icomoon-undo:before {
	content: "\e66b";
}
.icomoon-redo:before {
	content: "\e66c";
}
.icomoon-flip:before {
	content: "\e66d";
}
.icomoon-flip2:before {
	content: "\e66e";
}
.icomoon-undo2:before {
	content: "\e66f";
}
.icomoon-redo2:before {
	content: "\e670";
}
.icomoon-forward:before {
	content: "\e671";
}
.icomoon-reply:before {
	content: "\e672";
}
.icomoon-bubble:before {
	content: "\e673";
}
.icomoon-bubbles:before {
	content: "\e674";
}
.icomoon-bubbles2:before {
	content: "\e675";
}
.icomoon-bubble2:before {
	content: "\e676";
}
.icomoon-bubbles3:before {
	content: "\e677";
}
.icomoon-bubbles4:before {
	content: "\e678";
}
.icomoon-user:before {
	content: "\e679";
}
.icomoon-users:before {
	content: "\e67a";
}
.icomoon-user2:before {
	content: "\e67b";
}
.icomoon-users2:before {
	content: "\e67c";
}
.icomoon-user3:before {
	content: "\e67d";
}
.icomoon-user4:before {
	content: "\e67e";
}
.icomoon-quotes-left:before {
	content: "\e67f";
}
.icomoon-busy:before {
	content: "\e680";
}
.icomoon-spinner:before {
	content: "\e681";
}
.icomoon-spinner2:before {
	content: "\e682";
}
.icomoon-spinner3:before {
	content: "\e683";
}
.icomoon-spinner4:before {
	content: "\e684";
}
.icomoon-spinner5:before {
	content: "\e685";
}
.icomoon-spinner6:before {
	content: "\e686";
}
.icomoon-binoculars:before {
	content: "\e687";
}
.icomoon-search:before {
	content: "\e688";
}
.icomoon-zoom-in:before {
	content: "\e689";
}
.icomoon-zoom-out:before {
	content: "\e68a";
}
.icomoon-expand:before {
	content: "\e68b";
}
.icomoon-contract:before {
	content: "\e68c";
}
.icomoon-expand2:before {
	content: "\e68d";
}
.icomoon-contract2:before {
	content: "\e68e";
}
.icomoon-key:before {
	content: "\e68f";
}
.icomoon-key2:before {
	content: "\e690";
}
.icomoon-lock2:before {
	content: "\e691";
}
.icomoon-lock3:before {
	content: "\e692";
}
.icomoon-unlocked:before {
	content: "\e693";
}
.icomoon-wrench:before {
	content: "\e694";
}
.icomoon-settings:before {
	content: "\e695";
}
.icomoon-equalizer:before {
	content: "\e696";
}
.icomoon-cog:before {
	content: "\e697";
}
.icomoon-cogs:before {
	content: "\e698";
}
.icomoon-cog2:before {
	content: "\e699";
}
.icomoon-hammer:before {
	content: "\e69a";
}
.icomoon-wand:before {
	content: "\e69b";
}
.icomoon-aid:before {
	content: "\e69c";
}
.icomoon-bug:before {
	content: "\e69d";
}
.icomoon-pie:before {
	content: "\e69e";
}
.icomoon-stats:before {
	content: "\e69f";
}
.icomoon-bars:before {
	content: "\e6a0";
}
.icomoon-bars2:before {
	content: "\e6a1";
}
.icomoon-gift:before {
	content: "\e6a2";
}
.icomoon-trophy:before {
	content: "\e6a3";
}
.icomoon-glass:before {
	content: "\e6a4";
}
.icomoon-mug:before {
	content: "\e6a5";
}
.icomoon-food:before {
	content: "\e6a6";
}
.icomoon-leaf:before {
	content: "\e6a7";
}
.icomoon-rocket:before {
	content: "\e6a8";
}
.icomoon-meter:before {
	content: "\e6a9";
}
.icomoon-meter2:before {
	content: "\e6aa";
}
.icomoon-dashboard:before {
	content: "\e6ab";
}
.icomoon-hammer2:before {
	content: "\e6ac";
}
.icomoon-fire:before {
	content: "\e6ad";
}
.icomoon-lab:before {
	content: "\e6ae";
}
.icomoon-magnet:before {
	content: "\e6af";
}
.icomoon-remove:before {
	content: "\e6b0";
}
.icomoon-remove2:before {
	content: "\e6b1";
}
.icomoon-briefcase:before {
	content: "\e6b2";
}
.icomoon-airplane:before {
	content: "\e6b3";
}
.icomoon-truck:before {
	content: "\e6b4";
}
.icomoon-road:before {
	content: "\e6b5";
}
.icomoon-accessibility:before {
	content: "\e6b6";
}
.icomoon-target:before {
	content: "\e6b7";
}
.icomoon-shield:before {
	content: "\e6b8";
}
.icomoon-lightning:before {
	content: "\e6b9";
}
.icomoon-switch:before {
	content: "\e6ba";
}
.icomoon-power-cord:before {
	content: "\e6bb";
}
.icomoon-signup:before {
	content: "\e6bc";
}
.icomoon-list:before {
	content: "\e6bd";
}
.icomoon-list2:before {
	content: "\e6be";
}
.icomoon-numbered-list:before {
	content: "\e6bf";
}
.icomoon-menu:before {
	content: "\e6c0";
}
.icomoon-menu2:before {
	content: "\e6c1";
}
.icomoon-tree:before {
	content: "\e6c2";
}
.icomoon-cloud:before {
	content: "\e6c3";
}
.icomoon-cloud-download:before {
	content: "\e6c4";
}
.icomoon-cloud-upload:before {
	content: "\e6c5";
}
.icomoon-download2:before {
	content: "\e6c6";
}
.icomoon-upload3:before {
	content: "\e6c7";
}
.icomoon-download3:before {
	content: "\e6c8";
}
.icomoon-upload4:before {
	content: "\e6c9";
}
.icomoon-globe:before {
	content: "\e6ca";
}
.icomoon-earth2:before {
	content: "\e6cb";
}
.icomoon-link:before {
	content: "\e6cc";
}
.icomoon-flag:before {
	content: "\e6cd";
}
.icomoon-attachment:before {
	content: "\e6ce";
}
.icomoon-eye2:before {
	content: "\e6cf";
}
.icomoon-eye-blocked:before {
	content: "\e6d0";
}
.icomoon-eye3:before {
	content: "\e6d1";
}
.icomoon-bookmark:before {
	content: "\e6d2";
}
.icomoon-bookmarks:before {
	content: "\e6d3";
}
.icomoon-brightness-medium:before {
	content: "\e6d4";
}
.icomoon-brightness-contrast:before {
	content: "\e6d5";
}
.icomoon-contrast:before {
	content: "\e6d6";
}
.icomoon-star:before {
	content: "\e6d7";
}
.icomoon-star2:before {
	content: "\e6d8";
}
.icomoon-star3:before {
	content: "\e6d9";
}
.icomoon-heart:before {
	content: "\e6da";
}
.icomoon-heart2:before {
	content: "\e6db";
}
.icomoon-heart-broken:before {
	content: "\e6dc";
}
.icomoon-thumbs-up:before {
	content: "\e6dd";
}
.icomoon-thumbs-up2:before {
	content: "\e6de";
}
.icomoon-happy:before {
	content: "\e6df";
}
.icomoon-happy2:before {
	content: "\e6e0";
}
.icomoon-smiley:before {
	content: "\e6e1";
}
.icomoon-smiley2:before {
	content: "\e6e2";
}
.icomoon-tongue:before {
	content: "\e6e3";
}
.icomoon-tongue2:before {
	content: "\e6e4";
}
.icomoon-sad:before {
	content: "\e6e5";
}
.icomoon-sad2:before {
	content: "\e6e6";
}
.icomoon-wink:before {
	content: "\e6e7";
}
.icomoon-wink2:before {
	content: "\e6e8";
}
.icomoon-grin:before {
	content: "\e6e9";
}
.icomoon-grin2:before {
	content: "\e6ea";
}
.icomoon-cool:before {
	content: "\e6eb";
}
.icomoon-cool2:before {
	content: "\e6ec";
}
.icomoon-angry:before {
	content: "\e6ed";
}
.icomoon-angry2:before {
	content: "\e6ee";
}
.icomoon-evil:before {
	content: "\e6ef";
}
.icomoon-evil2:before {
	content: "\e6f0";
}
.icomoon-shocked:before {
	content: "\e6f1";
}
.icomoon-shocked2:before {
	content: "\e6f2";
}
.icomoon-confused:before {
	content: "\e6f3";
}
.icomoon-confused2:before {
	content: "\e6f4";
}
.icomoon-neutral:before {
	content: "\e6f5";
}
.icomoon-neutral2:before {
	content: "\e6f6";
}
.icomoon-wondering:before {
	content: "\e6f7";
}
.icomoon-wondering2:before {
	content: "\e6f8";
}
.icomoon-point-up:before {
	content: "\e6f9";
}
.icomoon-point-right:before {
	content: "\e6fa";
}
.icomoon-point-down:before {
	content: "\e6fb";
}
.icomoon-point-left:before {
	content: "\e6fc";
}
.icomoon-warning:before {
	content: "\e6fd";
}
.icomoon-notification:before {
	content: "\e6fe";
}
.icomoon-question:before {
	content: "\e6ff";
}
.icomoon-info:before {
	content: "\e700";
}
.icomoon-info2:before {
	content: "\e701";
}
.icomoon-blocked:before {
	content: "\e702";
}
.icomoon-cancel-circle:before {
	content: "\e703";
}
.icomoon-checkmark-circle:before {
	content: "\e704";
}
.icomoon-spam:before {
	content: "\e705";
}
.icomoon-close:before {
	content: "\e706";
}
.icomoon-checkmark:before {
	content: "\e707";
}
.icomoon-checkmark2:before {
	content: "\e708";
}
.icomoon-spell-check:before {
	content: "\e709";
}
.icomoon-minus:before {
	content: "\e70a";
}
.icomoon-plus:before {
	content: "\e70b";
}
.icomoon-enter:before {
	content: "\e70c";
}
.icomoon-exit:before {
	content: "\e70d";
}
.icomoon-play2:before {
	content: "\e70e";
}
.icomoon-pause:before {
	content: "\e70f";
}
.icomoon-stop:before {
	content: "\e710";
}
.icomoon-backward:before {
	content: "\e711";
}
.icomoon-forward2:before {
	content: "\e712";
}
.icomoon-play3:before {
	content: "\e713";
}
.icomoon-pause2:before {
	content: "\e714";
}
.icomoon-stop2:before {
	content: "\e715";
}
.icomoon-backward2:before {
	content: "\e716";
}
.icomoon-forward3:before {
	content: "\e717";
}
.icomoon-first:before {
	content: "\e718";
}
.icomoon-last:before {
	content: "\e719";
}
.icomoon-previous:before {
	content: "\e71a";
}
.icomoon-next:before {
	content: "\e71b";
}
.icomoon-eject:before {
	content: "\e71c";
}
.icomoon-volume-high:before {
	content: "\e71d";
}
.icomoon-volume-medium:before {
	content: "\e71e";
}
.icomoon-volume-low:before {
	content: "\e71f";
}
.icomoon-volume-mute:before {
	content: "\e720";
}
.icomoon-volume-mute2:before {
	content: "\e721";
}
.icomoon-volume-increase:before {
	content: "\e722";
}
.icomoon-volume-decrease:before {
	content: "\e723";
}
.icomoon-loop:before {
	content: "\e724";
}
.icomoon-loop2:before {
	content: "\e725";
}
.icomoon-loop3:before {
	content: "\e726";
}
.icomoon-shuffle:before {
	content: "\e727";
}
.icomoon-arrow-up-left:before {
	content: "\e728";
}
.icomoon-arrow-up:before {
	content: "\e729";
}
.icomoon-arrow-up-right:before {
	content: "\e72a";
}
.icomoon-arrow-right:before {
	content: "\e72b";
}
.icomoon-arrow-down-right:before {
	content: "\e72c";
}
.icomoon-arrow-down:before {
	content: "\e72d";
}
.icomoon-arrow-down-left:before {
	content: "\e72e";
}
.icomoon-arrow-left:before {
	content: "\e72f";
}
.icomoon-arrow-up-left2:before {
	content: "\e730";
}
.icomoon-arrow-up2:before {
	content: "\e731";
}
.icomoon-arrow-up-right2:before {
	content: "\e732";
}
.icomoon-arrow-right2:before {
	content: "\e733";
}
.icomoon-arrow-down-right2:before {
	content: "\e734";
}
.icomoon-arrow-down2:before {
	content: "\e735";
}
.icomoon-arrow-down-left2:before {
	content: "\e736";
}
.icomoon-arrow-left2:before {
	content: "\e737";
}
.icomoon-arrow-up-left3:before {
	content: "\e738";
}
.icomoon-arrow-up3:before {
	content: "\e739";
}
.icomoon-arrow-up-right3:before {
	content: "\e73a";
}
.icomoon-arrow-right3:before {
	content: "\e73b";
}
.icomoon-arrow-down-right3:before {
	content: "\e73c";
}
.icomoon-arrow-down3:before {
	content: "\e73d";
}
.icomoon-arrow-down-left3:before {
	content: "\e73e";
}
.icomoon-arrow-left3:before {
	content: "\e73f";
}
.icomoon-tab:before {
	content: "\e740";
}
.icomoon-checkbox-checked:before {
	content: "\e741";
}
.icomoon-checkbox-unchecked:before {
	content: "\e742";
}
.icomoon-checkbox-partial:before {
	content: "\e743";
}
.icomoon-radio-checked:before {
	content: "\e744";
}
.icomoon-radio-unchecked:before {
	content: "\e745";
}
.icomoon-crop:before {
	content: "\e746";
}
.icomoon-scissors:before {
	content: "\e747";
}
.icomoon-filter:before {
	content: "\e748";
}
.icomoon-filter2:before {
	content: "\e749";
}
.icomoon-font:before {
	content: "\e74a";
}
.icomoon-text-height:before {
	content: "\e74b";
}
.icomoon-text-width:before {
	content: "\e74c";
}
.icomoon-bold:before {
	content: "\e74d";
}
.icomoon-underline:before {
	content: "\e74e";
}
.icomoon-italic:before {
	content: "\e74f";
}
.icomoon-strikethrough:before {
	content: "\e750";
}
.icomoon-omega:before {
	content: "\e751";
}
.icomoon-sigma:before {
	content: "\e752";
}
.icomoon-table:before {
	content: "\e753";
}
.icomoon-table2:before {
	content: "\e754";
}
.icomoon-insert-template:before {
	content: "\e755";
}
.icomoon-pilcrow:before {
	content: "\e756";
}
.icomoon-left-toright:before {
	content: "\e757";
}
.icomoon-right-toleft:before {
	content: "\e758";
}
.icomoon-paragraph-left:before {
	content: "\e759";
}
.icomoon-paragraph-center:before {
	content: "\e75a";
}
.icomoon-paragraph-right:before {
	content: "\e75b";
}
.icomoon-paragraph-justify:before {
	content: "\e75c";
}
.icomoon-paragraph-left2:before {
	content: "\e75d";
}
.icomoon-paragraph-center2:before {
	content: "\e75e";
}
.icomoon-paragraph-right2:before {
	content: "\e75f";
}
.icomoon-paragraph-justify2:before {
	content: "\e760";
}
.icomoon-indent-increase:before {
	content: "\e761";
}
.icomoon-indent-decrease:before {
	content: "\e762";
}
.icomoon-new-tab:before {
	content: "\e763";
}
.icomoon-embed:before {
	content: "\e764";
}
.icomoon-code:before {
	content: "\e765";
}
.icomoon-console:before {
	content: "\e766";
}
.icomoon-share:before {
	content: "\e767";
}
.icomoon-mail:before {
	content: "\e768";
}
.icomoon-mail2:before {
	content: "\e769";
}
.icomoon-mail3:before {
	content: "\e76a";
}
.icomoon-mail4:before {
	content: "\e76b";
}
.icomoon-google:before {
	content: "\e76c";
}
.icomoon-google-plus:before {
	content: "\e76d";
}
.icomoon-google-plus2:before {
	content: "\e76e";
}
.icomoon-google-plus3:before {
	content: "\e76f";
}
.icomoon-google-plus4:before {
	content: "\e770";
}
.icomoon-google-drive:before {
	content: "\e771";
}
.icomoon-facebook:before {
	content: "\e772";
}
.icomoon-facebook2:before {
	content: "\e773";
}
.icomoon-facebook3:before {
	content: "\e774";
}
.icomoon-instagram:before {
	content: "\e775";
}
.icomoon-twitter:before {
	content: "\e776";
}
.icomoon-twitter2:before {
	content: "\e777";
}
.icomoon-twitter3:before {
	content: "\e778";
}
.icomoon-feed2:before {
	content: "\e779";
}
.icomoon-feed3:before {
	content: "\e77a";
}
.icomoon-feed4:before {
	content: "\e77b";
}
.icomoon-youtube:before {
	content: "\e77c";
}
.icomoon-youtube2:before {
	content: "\e77d";
}
.icomoon-vimeo:before {
	content: "\e77e";
}
.icomoon-vimeo2:before {
	content: "\e77f";
}
.icomoon-vimeo3:before {
	content: "\e780";
}
.icomoon-lanyrd:before {
	content: "\e781";
}
.icomoon-flickr:before {
	content: "\e782";
}
.icomoon-flickr2:before {
	content: "\e783";
}
.icomoon-flickr3:before {
	content: "\e784";
}
.icomoon-flickr4:before {
	content: "\e785";
}
.icomoon-picassa:before {
	content: "\e786";
}
.icomoon-picassa2:before {
	content: "\e787";
}
.icomoon-dribbble:before {
	content: "\e788";
}
.icomoon-dribbble2:before {
	content: "\e789";
}
.icomoon-dribbble3:before {
	content: "\e78a";
}
.icomoon-forrst:before {
	content: "\e78b";
}
.icomoon-forrst2:before {
	content: "\e78c";
}
.icomoon-deviantart:before {
	content: "\e78d";
}
.icomoon-deviantart2:before {
	content: "\e78e";
}
.icomoon-steam:before {
	content: "\e78f";
}
.icomoon-steam2:before {
	content: "\e790";
}
.icomoon-github:before {
	content: "\e791";
}
.icomoon-github2:before {
	content: "\e792";
}
.icomoon-github3:before {
	content: "\e793";
}
.icomoon-github4:before {
	content: "\e794";
}
.icomoon-github5:before {
	content: "\e795";
}
.icomoon-wordpress:before {
	content: "\e796";
}
.icomoon-wordpress2:before {
	content: "\e797";
}
.icomoon-joomla:before {
	content: "\e798";
}
.icomoon-blogger:before {
	content: "\e799";
}
.icomoon-blogger2:before {
	content: "\e79a";
}
.icomoon-tumblr:before {
	content: "\e79b";
}
.icomoon-tumblr2:before {
	content: "\e79c";
}
.icomoon-yahoo:before {
	content: "\e79d";
}
.icomoon-tux:before {
	content: "\e79e";
}
.icomoon-apple:before {
	content: "\e79f";
}
.icomoon-finder:before {
	content: "\e7a0";
}
.icomoon-android:before {
	content: "\e7a1";
}
.icomoon-windows:before {
	content: "\e7a2";
}
.icomoon-windows8:before {
	content: "\e7a3";
}
.icomoon-soundcloud:before {
	content: "\e7a4";
}
.icomoon-soundcloud2:before {
	content: "\e7a5";
}
.icomoon-skype:before {
	content: "\e7a6";
}
.icomoon-reddit:before {
	content: "\e7a7";
}
.icomoon-linkedin:before {
	content: "\e7a8";
}
.icomoon-lastfm:before {
	content: "\e7a9";
}
.icomoon-lastfm2:before {
	content: "\e7aa";
}
.icomoon-delicious:before {
	content: "\e7ab";
}
.icomoon-stumbleupon:before {
	content: "\e7ac";
}
.icomoon-stumbleupon2:before {
	content: "\e7ad";
}
.icomoon-stackoverflow:before {
	content: "\e7ae";
}
.icomoon-pinterest:before {
	content: "\e7af";
}
.icomoon-pinterest2:before {
	content: "\e7b0";
}
.icomoon-xing:before {
	content: "\e7b1";
}
.icomoon-xing2:before {
	content: "\e7b2";
}
.icomoon-flattr:before {
	content: "\e7b3";
}
.icomoon-foursquare:before {
	content: "\e7b4";
}
.icomoon-foursquare2:before {
	content: "\e7b5";
}
.icomoon-paypal:before {
	content: "\e7b6";
}
.icomoon-paypal2:before {
	content: "\e7b7";
}
.icomoon-paypal3:before {
	content: "\e7b8";
}
.icomoon-yelp:before {
	content: "\e7b9";
}
.icomoon-libreoffice:before {
	content: "\e7ba";
}
.icomoon-file-pdf:before {
	content: "\e7bb";
}
.icomoon-file-openoffice:before {
	content: "\e7bc";
}
.icomoon-file-word:before {
	content: "\e7bd";
}
.icomoon-file-excel:before {
	content: "\e7be";
}
.icomoon-file-zip:before {
	content: "\e7bf";
}
.icomoon-file-powerpoint:before {
	content: "\e7c0";
}
.icomoon-file-xml:before {
	content: "\e7c1";
}
.icomoon-file-css:before {
	content: "\e7c2";
}
.icomoon-html5:before {
	content: "\e7c3";
}
.icomoon-html52:before {
	content: "\e7c4";
}
.icomoon-css3:before {
	content: "\e7c5";
}
.icomoon-chrome:before {
	content: "\e7c6";
}
.icomoon-firefox:before {
	content: "\e7c7";
}
.icomoon-IE:before {
	content: "\e7c8";
}
.icomoon-opera:before {
	content: "\e7c9";
}
.icomoon-safari:before {
	content: "\e7ca";
}
.icomoon-IcoMoon:before {
	content: "\e7cb";
}
