@font-face {
	font-family: 'entypo';
	src:url('#{$fontsPath}/entypo.eot');
	src:url('#{$fontsPath}/entypo.eot?#iefix') format('embedded-opentype'),
		url('#{$fontsPath}/entypo.ttf') format('truetype'),
		url('#{$fontsPath}/entypo.woff') format('woff'),
		url('#{$fontsPath}/entypo.svg#entypo') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="entypo-"], [class*=" entypo-"] {
	font-family: 'entypo';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.entypo-phone:before {
	content: "\e609";
}
.entypo-mobile:before {
	content: "\e60a";
}
.entypo-mouse:before {
	content: "\e60b";
}
.entypo-directions:before {
	content: "\e60c";
}
.entypo-mail:before {
	content: "\e60d";
}
.entypo-paperplane:before {
	content: "\e60e";
}
.entypo-pencil:before {
	content: "\e60f";
}
.entypo-feather:before {
	content: "\e610";
}
.entypo-paperclip:before {
	content: "\e611";
}
.entypo-drawer:before {
	content: "\e612";
}
.entypo-reply:before {
	content: "\e613";
}
.entypo-reply-all:before {
	content: "\e614";
}
.entypo-forward:before {
	content: "\e615";
}
.entypo-user:before {
	content: "\e616";
}
.entypo-users:before {
	content: "\e617";
}
.entypo-user-add:before {
	content: "\e618";
}
.entypo-vcard:before {
	content: "\e619";
}
.entypo-export:before {
	content: "\e604";
}
.entypo-location:before {
	content: "\e61a";
}
.entypo-map:before {
	content: "\e61b";
}
.entypo-compass:before {
	content: "\e61c";
}
.entypo-location2:before {
	content: "\e61d";
}
.entypo-target:before {
	content: "\e61e";
}
.entypo-share:before {
	content: "\e61f";
}
.entypo-sharable:before {
	content: "\e620";
}
.entypo-heart:before {
	content: "\e621";
}
.entypo-heart2:before {
	content: "\e622";
}
.entypo-star:before {
	content: "\e623";
}
.entypo-star2:before {
	content: "\e624";
}
.entypo-thumbs-up:before {
	content: "\e625";
}
.entypo-thumbs-down:before {
	content: "\e626";
}
.entypo-chat:before {
	content: "\e606";
}
.entypo-comment:before {
	content: "\e627";
}
.entypo-quote:before {
	content: "\e628";
}
.entypo-house:before {
	content: "\e629";
}
.entypo-popup:before {
	content: "\e62a";
}
.entypo-search:before {
	content: "\e62b";
}
.entypo-flashlight:before {
	content: "\e62c";
}
.entypo-printer:before {
	content: "\e62d";
}
.entypo-bell:before {
	content: "\e62e";
}
.entypo-link:before {
	content: "\e62f";
}
.entypo-flag:before {
	content: "\e630";
}
.entypo-cog:before {
	content: "\e631";
}
.entypo-tools:before {
	content: "\e603";
}
.entypo-trophy:before {
	content: "\e632";
}
.entypo-tag:before {
	content: "\e633";
}
.entypo-camera:before {
	content: "\e634";
}
.entypo-megaphone:before {
	content: "\e635";
}
.entypo-moon:before {
	content: "\e636";
}
.entypo-palette:before {
	content: "\e637";
}
.entypo-leaf:before {
	content: "\e638";
}
.entypo-music:before {
	content: "\e639";
}
.entypo-music2:before {
	content: "\e63a";
}
.entypo-new:before {
	content: "\e63b";
}
.entypo-graduation:before {
	content: "\e63c";
}
.entypo-book:before {
	content: "\e63d";
}
.entypo-newspaper:before {
	content: "\e63e";
}
.entypo-bag:before {
	content: "\e63f";
}
.entypo-airplane:before {
	content: "\e640";
}
.entypo-lifebuoy:before {
	content: "\e641";
}
.entypo-eye:before {
	content: "\e605";
}
.entypo-clock:before {
	content: "\e642";
}
.entypo-microphone:before {
	content: "\e643";
}
.entypo-calendar:before {
	content: "\e644";
}
.entypo-bolt:before {
	content: "\e645";
}
.entypo-thunder:before {
	content: "\e646";
}
.entypo-droplet:before {
	content: "\e647";
}
.entypo-cd:before {
	content: "\e648";
}
.entypo-briefcase:before {
	content: "\e649";
}
.entypo-air:before {
	content: "\e64a";
}
.entypo-hourglass:before {
	content: "\e64b";
}
.entypo-gauge:before {
	content: "\e64c";
}
.entypo-language:before {
	content: "\e64d";
}
.entypo-network:before {
	content: "\e64e";
}
.entypo-key:before {
	content: "\e64f";
}
.entypo-battery:before {
	content: "\e650";
}
.entypo-bucket:before {
	content: "\e651";
}
.entypo-magnet:before {
	content: "\e652";
}
.entypo-drive:before {
	content: "\e653";
}
.entypo-cup:before {
	content: "\e654";
}
.entypo-rocket:before {
	content: "\e655";
}
.entypo-brush:before {
	content: "\e656";
}
.entypo-suitcase:before {
	content: "\e657";
}
.entypo-cone:before {
	content: "\e658";
}
.entypo-earth:before {
	content: "\e602";
}
.entypo-keyboard:before {
	content: "\e659";
}
.entypo-browser:before {
	content: "\e65a";
}
.entypo-publish:before {
	content: "\e600";
}
.entypo-progress-3:before {
	content: "\e65b";
}
.entypo-progress-2:before {
	content: "\e65c";
}
.entypo-brogress-1:before {
	content: "\e65d";
}
.entypo-progress-0:before {
	content: "\e65e";
}
.entypo-sun:before {
	content: "\e65f";
}
.entypo-sun2:before {
	content: "\e660";
}
.entypo-adjust:before {
	content: "\e661";
}
.entypo-code:before {
	content: "\e662";
}
.entypo-screen:before {
	content: "\e601";
}
.entypo-infinity:before {
	content: "\e663";
}
.entypo-light-bulb:before {
	content: "\e664";
}
.entypo-credit-card:before {
	content: "\e665";
}
.entypo-database:before {
	content: "\e666";
}
.entypo-voicemail:before {
	content: "\e667";
}
.entypo-clipboard:before {
	content: "\e668";
}
.entypo-cart:before {
	content: "\e669";
}
.entypo-box:before {
	content: "\e66a";
}
.entypo-ticket:before {
	content: "\e66b";
}
.entypo-rss:before {
	content: "\e66c";
}
.entypo-signal:before {
	content: "\e66d";
}
.entypo-thermometer:before {
	content: "\e66e";
}
.entypo-droplets:before {
	content: "\e66f";
}
.entypo-uniE670:before {
	content: "\e670";
}
.entypo-statistics:before {
	content: "\e671";
}
.entypo-pie:before {
	content: "\e672";
}
.entypo-bars:before {
	content: "\e673";
}
.entypo-graph:before {
	content: "\e674";
}
.entypo-lock:before {
	content: "\e608";
}
.entypo-lock-open:before {
	content: "\e675";
}
.entypo-logout:before {
	content: "\e676";
}
.entypo-login:before {
	content: "\e677";
}
.entypo-checkmark:before {
	content: "\e678";
}
.entypo-cross:before {
	content: "\e679";
}
.entypo-minus:before {
	content: "\e67a";
}
.entypo-plus:before {
	content: "\e67b";
}
.entypo-cross2:before {
	content: "\e67c";
}
.entypo-minus2:before {
	content: "\e67d";
}
.entypo-plus2:before {
	content: "\e67e";
}
.entypo-cross3:before {
	content: "\e67f";
}
.entypo-minus3:before {
	content: "\e680";
}
.entypo-plus3:before {
	content: "\e681";
}
.entypo-erase:before {
	content: "\e682";
}
.entypo-blocked:before {
	content: "\e683";
}
.entypo-info:before {
	content: "\e684";
}
.entypo-info2:before {
	content: "\e685";
}
.entypo-question:before {
	content: "\e686";
}
.entypo-help:before {
	content: "\e687";
}
.entypo-warning:before {
	content: "\e688";
}
.entypo-cycle:before {
	content: "\e689";
}
.entypo-cw:before {
	content: "\e68a";
}
.entypo-ccw:before {
	content: "\e68b";
}
.entypo-shuffle:before {
	content: "\e68c";
}
.entypo-arrow:before {
	content: "\e68d";
}
.entypo-arrow2:before {
	content: "\e68e";
}
.entypo-retweet:before {
	content: "\e68f";
}
.entypo-loop:before {
	content: "\e690";
}
.entypo-history:before {
	content: "\e691";
}
.entypo-back:before {
	content: "\e692";
}
.entypo-switch:before {
	content: "\e693";
}
.entypo-list:before {
	content: "\e694";
}
.entypo-add-to-list:before {
	content: "\e695";
}
.entypo-layout:before {
	content: "\e696";
}
.entypo-list2:before {
	content: "\e697";
}
.entypo-text:before {
	content: "\e698";
}
.entypo-text2:before {
	content: "\e699";
}
.entypo-document:before {
	content: "\e69a";
}
.entypo-docs:before {
	content: "\e69b";
}
.entypo-landscape:before {
	content: "\e69c";
}
.entypo-pictures:before {
	content: "\e69d";
}
.entypo-video:before {
	content: "\e69e";
}
.entypo-music3:before {
	content: "\e69f";
}
.entypo-folder:before {
	content: "\e6a0";
}
.entypo-archive:before {
	content: "\e6a1";
}
.entypo-trash:before {
	content: "\e6a2";
}
.entypo-upload:before {
	content: "\e607";
}
.entypo-download:before {
	content: "\e6a3";
}
.entypo-disk:before {
	content: "\e6a4";
}
.entypo-install:before {
	content: "\e6a5";
}
.entypo-cloud:before {
	content: "\e6a6";
}
.entypo-upload:before {
	content: "\e6a7";
}
.entypo-bookmark:before {
	content: "\e6a8";
}
.entypo-bookmarks:before {
	content: "\e6a9";
}
.entypo-book2:before {
	content: "\e6aa";
}
.entypo-play:before {
	content: "\e6ab";
}
.entypo-pause:before {
	content: "\e6ac";
}
.entypo-record:before {
	content: "\e6ad";
}
.entypo-stop:before {
	content: "\e6ae";
}
.entypo-next:before {
	content: "\e6af";
}
.entypo-previous:before {
	content: "\e6b0";
}
.entypo-first:before {
	content: "\e6b1";
}
.entypo-last:before {
	content: "\e6b2";
}
.entypo-resize-enlarge:before {
	content: "\e6b3";
}
.entypo-resize-shrink:before {
	content: "\e6b4";
}
.entypo-volume:before {
	content: "\e6b5";
}
.entypo-sound:before {
	content: "\e6b6";
}
.entypo-mute:before {
	content: "\e6b7";
}
.entypo-flow-cascade:before {
	content: "\e6b8";
}
.entypo-flow-branch:before {
	content: "\e6b9";
}
.entypo-flow-tree:before {
	content: "\e6ba";
}
.entypo-flow-line:before {
	content: "\e6bb";
}
.entypo-flow-parallel:before {
	content: "\e6bc";
}
.entypo-arrow-left:before {
	content: "\e6bd";
}
.entypo-arrow-down:before {
	content: "\e6be";
}
.entypo-arrow-up--upload:before {
	content: "\e6bf";
}
.entypo-arrow-right:before {
	content: "\e6c0";
}
.entypo-arrow-left2:before {
	content: "\e6c1";
}
.entypo-arrow-down2:before {
	content: "\e6c2";
}
.entypo-arrow-up:before {
	content: "\e6c3";
}
.entypo-arrow-right2:before {
	content: "\e6c4";
}
.entypo-arrow-left3:before {
	content: "\e6c5";
}
.entypo-arrow-down3:before {
	content: "\e6c6";
}
.entypo-arrow-up2:before {
	content: "\e6c7";
}
.entypo-arrow-right3:before {
	content: "\e6c8";
}
.entypo-arrow-left4:before {
	content: "\e6c9";
}
.entypo-arrow-down4:before {
	content: "\e6ca";
}
.entypo-arrow-up3:before {
	content: "\e6cb";
}
.entypo-arrow-right4:before {
	content: "\e6cc";
}
.entypo-arrow-left5:before {
	content: "\e6cd";
}
.entypo-arrow-down5:before {
	content: "\e6ce";
}
.entypo-arrow-up4:before {
	content: "\e6cf";
}
.entypo-arrow-right5:before {
	content: "\e6d0";
}
.entypo-arrow-left6:before {
	content: "\e6d1";
}
.entypo-arrow-down6:before {
	content: "\e6d2";
}
.entypo-arrow-up5:before {
	content: "\e6d3";
}
.entypo-arrow-right6:before {
	content: "\e6d4";
}
.entypo-arrow-left7:before {
	content: "\e6d5";
}
.entypo-arrow-down7:before {
	content: "\e6d6";
}
.entypo-arrow-up6:before {
	content: "\e6d7";
}
.entypo-uniE6D8:before {
	content: "\e6d8";
}
.entypo-arrow-left8:before {
	content: "\e6d9";
}
.entypo-arrow-down8:before {
	content: "\e6da";
}
.entypo-arrow-up7:before {
	content: "\e6db";
}
.entypo-arrow-right7:before {
	content: "\e6dc";
}
.entypo-menu:before {
	content: "\e6dd";
}
.entypo-ellipsis:before {
	content: "\e6de";
}
.entypo-dots:before {
	content: "\e6df";
}
.entypo-dot:before {
	content: "\e6e0";
}
.entypo-cc:before {
	content: "\e6e1";
}
.entypo-cc-by:before {
	content: "\e6e2";
}
.entypo-cc-nc:before {
	content: "\e6e3";
}
.entypo-cc-nc-eu:before {
	content: "\e6e4";
}
.entypo-cc-nc-jp:before {
	content: "\e6e5";
}
.entypo-cc-sa:before {
	content: "\e6e6";
}
.entypo-cc-nd:before {
	content: "\e6e7";
}
.entypo-cc-pd:before {
	content: "\e6e8";
}
.entypo-cc-zero:before {
	content: "\e6e9";
}
.entypo-cc-share:before {
	content: "\e6ea";
}
.entypo-cc-share2:before {
	content: "\e6eb";
}
.entypo-daniel-bruce:before {
	content: "\e6ec";
}
.entypo-daniel-bruce2:before {
	content: "\e6ed";
}
.entypo-github:before {
	content: "\e6ee";
}
.entypo-github2:before {
	content: "\e6ef";
}
.entypo-flickr:before {
	content: "\e6f0";
}
.entypo-flickr2:before {
	content: "\e6f1";
}
.entypo-vimeo:before {
	content: "\e6f2";
}
.entypo-vimeo2:before {
	content: "\e6f3";
}
.entypo-twitter:before {
	content: "\e6f4";
}
.entypo-twitter2:before {
	content: "\e6f5";
}
.entypo-facebook:before {
	content: "\e6f6";
}
.entypo-facebook2:before {
	content: "\e6f7";
}
.entypo-facebook3:before {
	content: "\e6f8";
}
.entypo-googleplus:before {
	content: "\e6f9";
}
.entypo-googleplus2:before {
	content: "\e6fa";
}
.entypo-pinterest:before {
	content: "\e6fb";
}
.entypo-pinterest2:before {
	content: "\e6fc";
}
.entypo-tumblr:before {
	content: "\e6fd";
}
.entypo-tumblr2:before {
	content: "\e6fe";
}
.entypo-linkedin:before {
	content: "\e6ff";
}
.entypo-linkedin2:before {
	content: "\e700";
}
.entypo-dribbble:before {
	content: "\e701";
}
.entypo-dribbble2:before {
	content: "\e702";
}
.entypo-stumbleupon:before {
	content: "\e703";
}
.entypo-stumbleupon2:before {
	content: "\e704";
}
.entypo-lastfm:before {
	content: "\e705";
}
.entypo-lastfm2:before {
	content: "\e706";
}
.entypo-rdio:before {
	content: "\e707";
}
.entypo-rdio2:before {
	content: "\e708";
}
.entypo-spotify:before {
	content: "\e709";
}
.entypo-spotify2:before {
	content: "\e70a";
}
.entypo-qq:before {
	content: "\e70b";
}
.entypo-instagram:before {
	content: "\e70c";
}
.entypo-dropbox:before {
	content: "\e70d";
}
.entypo-evernote:before {
	content: "\e70e";
}
.entypo-flattr:before {
	content: "\e70f";
}
.entypo-skype:before {
	content: "\e710";
}
.entypo-skype2:before {
	content: "\e711";
}
.entypo-renren:before {
	content: "\e712";
}
.entypo-sina-weibo:before {
	content: "\e713";
}
.entypo-paypal:before {
	content: "\e714";
}
.entypo-picasa:before {
	content: "\e715";
}
.entypo-soundcloud:before {
	content: "\e716";
}
.entypo-mixi:before {
	content: "\e717";
}
.entypo-behance:before {
	content: "\e718";
}
.entypo-circles:before {
	content: "\e719";
}
.entypo-vk:before {
	content: "\e71a";
}
.entypo-smashing:before {
	content: "\e71b";
}
