.services-intro {
  margin-top: 100px;

  @media(min-width: 992px) {
    .row {
      border-bottom: 1px solid #E6E6E8;
    }
  }

  .image {
    @media(max-width: 991px) {
      text-align: center;
    }
  }

  .info {
    @media(max-width: 767px) {
      text-align: center;
    }

    h3 {
      font-weight: 400;
      font-size: 25px;
      margin-top: 30px;

      @media(min-width: 991px) {
        margin-top: 70px;
      }
    }

    p {
      color: #666;
      margin-top: 25px;
      line-height: 23px;
    }
  }
}

.services-tabs {
  margin-top: 140px;

  @media(max-width: 991px) {
    margin-top: 70px;
  }

  .nav-tabs {
    text-align: center;
    border-bottom: 0;

    .nav-link {
      background: url("../images/hexagon.png") 0 0 no-repeat;
      width: 100px;
      height: 113px;
      border: 0;
      position: relative;
      display: inline-block;

      @media(max-width: 991px) {
        margin: 5px;
      }

      &:hover,
      &.active {
        background-position: 0 -117px;
        .icon {
          color: #fff;
        }
      }

      &:last-child {
        margin-right: 0px;
      }

      .icon {
        font-size: 48px;
        color: #80939b;
        position: absolute;
        top: 32px;
        left: 0;
        right: 0;
        text-align: center;
      }
    }
  }

  .tab-content {
    margin-top: 40px;

    .tab-pane {
      h4 {
        margin-bottom: 18px;
      }

      p {
        line-height: 25px;
      }
    }
  }
}

.services-grid-section {
  margin-top: 140px;

  @media(max-width: 767px) {
    margin-top: 90px;
  }

  &--border {
    .header {
      border-top: 1px solid #E6E6E8;
      padding-top: 70px;
    }
  }

  .header {
    text-align: center;

    h3 {
      font-size: 26px;
      color: #555C68;
      line-height: 33px;
      font-weight: 400;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      color: #888;
    }
  }

  .services {
    margin-top: 70px;

    .service {
      text-align: center;

      @media(max-width: 991px) {
        margin-bottom: 40px;
      }

      .pic {
        display: inline-block;
        padding: 15px;
        background: #f5f5f5;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
      }

      .info {
        margin-top: 25px;
        text-align: center;

        @media(min-width: 992px) {
          margin-left: 50px;
          width: 80%;
          text-align: left;
        }

        strong {
          font-weight: 500;
          font-size: 18px;
          color: #555;
        }

        p {
          color: #666;
          margin-top: 15px;
          line-height: 22px;
        }
      }
    }
  }
}

.services-showcase-section {
  margin-top: 100px;

  @media(max-width: 991px) {
    margin-top: 50px;
  }

  .header {
    border-top: 1px solid #E6E6E8;
    padding-top: 70px;
    text-align: center;

    h3 {
      font-size: 26px;
      color: #444;
      line-height: 25px;
      font-weight: 500;
    }

    p {
      font-size: 15px;
      font-weight: 400;
      color: #666;
      line-height: 24px;
      margin-top: 15px;
    }
  }

  .services {
    margin-top: 50px;

    .service {      
      @media(max-width: 991px) {
        text-align: center;
        margin-bottom: 30px;
      }

      img {
        max-width: 40px;
        position: relative;
        top: 10px;

        @media(min-width: 992px) {
          float: left;
        }
      }

      .info {
        @media(min-width: 992px) {
          float: left;
          width: 80%;
          margin-left: 20px;
        }

        strong {
          display: block;
          font-weight: 500;
          font-size: 16px;
          color: #444;
          margin-top: 15px;
        }

        p {
          font-weight: normal;
          font-size: 14px;
          color: #797979;
          margin-top: 10px;
          line-height: 24px;
        }
      }       
    }
  }
}

.signup-cta {
  margin-top: 120px;

  .wrapper {
    background: #F7F8FB;
    border: 1px solid #EAEDF7;
    border-radius: 5px;
    text-align: center;
    padding: 30px 20px;
  }

  h4 {
    font-size: 20px;
    color: #5F6E7C;
    font-weight: 400;
    margin-top: 15px;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.8);

    @media(max-width: 767px) {
      font-size: 17px;
      line-height: 23px;
    }
  }
}
